/* * TOP NAVIGATION STYLES* */
.TopNav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px;
  margin-right: 50px;
}

.TopNav-link {
  color: hsl(0, 0%, 100%);
  font-size: 1.125rem;
  font-weight: 500;
  margin-right: 2rem;
  margin-left: 2rem;
  letter-spacing: 0.125px;
  text-decoration: none;
  text-transform: uppercase;
}

.TopNav-link:hover {
  font-weight: 900;
}

/* * BANNER * */

/* * * MEDIA QUERIES * * */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .TopNav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 10px;
  }

  .TopNav-link {
    color: hsl(0, 0%, 100%);
    font-size: 1.125rem;
    font-weight: 500;
    margin-left: 1.25rem;
    letter-spacing: 0.125px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
