/* * COMPONENT STYLES * */

/* * * MEDIA QUERIES * * */

.bios {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.biosOne {
  color: white;
  min-width: 30%;
  min-height: 100%;
  padding: 30px;
}

.biosTwo {
  color: white;
  padding: 30px;
  /* display: flex;
    justify-content: center; */
}

.app-me {
  display: block;
  padding-bottom: 20px;
}

.me {
  height: 200px;
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .bios {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .biosOne {
    color: white;
    width: 40%;
    min-height: 100%;
    padding: 30px;
  }

  .biosTwo {
    color: white;
    padding: 30px;
    width: 40%;
    min-height: 100%;
    /* display: flex;
      justify-content: center; */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .bios {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .bios {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
