/* * APP HEADER * */
* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;

  color: white;
}

.App {
  background-color: rgb(71, 70, 82);
  padding-bottom: 20px;
  position: relative;
}

.center {
  text-align: center;
}

.App-logo {
  font-size: 1.5rem;
  pointer-events: none;
}

.App-header {
  color: hsl(193, 95%, 97%);
  display: flex;
  padding-top: 30px;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.logo {
  height: 40px;
  margin: 10px;
}

.stick-container {
  position: relative;
}

/* .sticky {
  position: fixed;
  z-index: 1;
} 

/* * * MEDIA QUERIES * * */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .Title {
    font-size: 34pt;
    margin: 0px;
    text-shadow: 0px 0px 80px rgba(0, 0, 0);
  }

  .Sub-title {
    font-size: 18pt;
    text-shadow: 0px 0px 80px rgba(0, 0, 0);
  }
  .logo {
    height: 40px;
    margin: 10px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 767px) {
  .logo {
    height: 40px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .logo {
    height: 45px;
    margin: 10px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .logo {
    height: 50px;
    margin: 10px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .logo {
    height: 50px;
    margin: 10px;
    margin: 10px;
  }
}
